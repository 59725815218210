<template>
  <v-data-table
    dense
    :headers="headers"
    :items="items"
    item-key="name"
    class="elevation-0"
  >
    <template v-slot:item.name="{ item }">
      <div class="my-3">
        <h3>{{ item.name }}</h3>
        <div><router-link :to="`/npm/${item.id}`">Edit</router-link></div>
      </div>
    </template>
    <template v-slot:item.public="{ item }">
      <v-icon small color="success" v-if="!item.public">fa-check-circle</v-icon>
      <v-icon small color="warning" v-else>fa-times-circle</v-icon>
    </template>
    <template v-slot:item.latestVersion="{ item }">
      <v-chip small label>{{ item.latestVersion }}</v-chip>
    </template>
    <template v-slot:item.totalFileSize="{ item }">
      {{ item.totalFileSize | fileSize }}
    </template>
  </v-data-table>
</template>

<script>
import { fileSize, niceDate } from "@/filters";

export default {
  props: ["items"],
  filters: { fileSize, niceDate },
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Private?",
        value: "public",
      },
      {
        text: "Latest version",
        value: "latestVersion",
      },
      {
        text: "Number of versions",
        value: "versionCount",
      },
      {
        text: "Total file size",
        value: "totalFileSize",
      },
    ],
  }),
};
</script>

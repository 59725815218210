<template>
  <v-row class="ma-4">
    <page-header>
      <template v-slot:subtitle>
        <div class="grey--text mt-3 text--darken-1">
          NPM URL:
          <span class="font-weight-bold primary--text">{{
            repositoryUrl
          }}</span>
        </div>
        <n-p-m-help-dialog :url="repositoryUrl" />
      </template>
    </page-header>
    <v-col cols="12">
      <v-card tile>
        <table-header
          @refresh="$apollo.queries.npmPackages.refetch()"
          title="Packages"
        />
        <v-card-text>
          <n-p-m-packages :items="npmPackages" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { queries } from "@/gql";
import PageHeader from "@/components/widgets/PageHeader.vue";
import TableHeader from "@/components/widgets/TableHeader";
import NPMPackages from "@/components/tables/NPMPackages";
import { API_HOST } from "@/config";
import NPMHelpDialog from "@/components/dialogs/NPMHelpDialog";

const { npmPackages, account } = queries;

export default {
  components: { NPMHelpDialog, NPMPackages, TableHeader, PageHeader },
  apollo: { npmPackages, account },
  computed: {
    repositoryUrl() {
      if (this.account) {
        return `${API_HOST}/npm/${this.account.hashId}/`;
      }
      return "";
    },
  },
};
</script>

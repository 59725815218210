<template>
  <help-dialog
    header="Publishing NPM packages to your Packagr NPM registry"
    :example="example"
    btn-text="See publish commands"
  >
    <template v-slot:header>
      <v-card-text>
        <v-alert type="info" dense text class="mb-0">
          In order to publish NPM packages to your Packagr NPM registry, you
          must first create an
          <router-link to="/service-accounts">Access token</router-link> with
          the role <span class="font-weight-bold">NPM package full access</span>
        </v-alert>
      </v-card-text>
    </template>
    <template v-slot:footer>
      For full details on how to build and push Docker images, please refer to
      <a href="https://docs.packagr.app">the docs</a>
    </template>
  </help-dialog>
</template>

<script>
import HelpDialog from "@/components/base/HelpDialog";

export default {
  components: { HelpDialog },
  props: ["url"],
  computed: {
    example() {
      if (this.url) {
        return `mkdir myproject && cd myproject
npm init --scope=@packagr

npm config set @packagr:registry ${this.url}
npm config set ${this.url
          .replace("http:", "")
          .replace("https:", "")}:_authToken $MY_NPM_ACCESS_TOKEN

npm publish
        `;
      }
      return "";
    },
  },
};
</script>
